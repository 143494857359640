<script setup lang="ts">
import Player from 'xgplayer'

// import Mp4Plugin from 'xgplayer-mp4'
import { map } from 'rxjs/operators'
import { showToast } from 'vant'
import { PlayerApi } from '~/net/apis/player_api'
import { CacheApi } from '~/net/apis/cache_api'
import { setViewStorage, deepEqual } from '~/utils/utils'
import { getNewVideo } from '~/utils/helper'

import icon_back from '~/assets/image/icon/icon_back.png'

const player = shallowRef<Player | null>(null)

const router = useRouter()
const videoId = useRouteParam<string>('id')
const onClickLeft = () => history.back()

const relateGuessData = useObservable(
  from(
    PlayerApi.relatedGuessVideo({
      id: videoId.value
    })
  ).pipe(map(({ data }) => data))
)
// 猜你喜欢
const guessList = computed(() => relateGuessData.value?.guessVideoList || [])
// 影片相关
const relateList = computed(() => relateGuessData.value?.relateVideoList || [])
const videoExtend = useObservable(
  from(
    PlayerApi.query({
      videoId: videoId.value
    })
  ).pipe(map(({ data }) => data))
)

const categoryTagNameList = computed(() => videoExtend.value?.javVideoDTO?.categoryTagNameList || [])
const categoryTagIdList = computed(() => videoExtend.value?.javVideoDTO?.categoryTagIdList || [])
// 全部 日本 欧美 中国 韩国 其他
const nationFilter = [
  { index: 0, title: '全部', flag: '', isSelected: true },
  { index: 1, title: '日本', flag: '1', isSelected: false },
  { index: 2, title: '欧美', flag: '2', isSelected: false },
  { index: 3, title: '中国', flag: '3', isSelected: false },
  { index: 4, title: '韩国', flag: '4', isSelected: false },
  { index: 4, title: '其他', flag: '99', isSelected: false }
]
const nation = computed(() =>
  nationFilter.find((r) => Number(r.flag) === (videoExtend.value?.javVideoDTO?.actressNationality || 99))
)
const like = ref(false)
async function handleLikeToggle() {
  await PlayerApi.collect({ videoId: videoId.value })
  like.value = !like.value
}
async function cacheVideoo() {
  const { data } = await CacheApi.cacheVideo({ videoId: videoId.value })
  showToast('正在下载')
  window.open(data, '_blank')
}

function getPlaybackPercentage(currentTime: number, duration: number) {
  // 如果 duration 是 NaN，返回 1%
  if (isNaN(duration) || isNaN(currentTime) || !duration) {
    return 1
  }

  // 计算百分比，确保百分比不超过 100%
  const percentage = Math.min((currentTime / duration) * 100, 100)
  return Math.max(Math.floor(percentage), 1) // 确保百分比至少为 1%
}

watch(
  () => videoExtend.value,
  (newVal, oldVal) => {
    console.log('watch>>>>>')
    if (!deepEqual(newVal, oldVal)) {
      init()
    }
  }
)

onMounted(() => {

})
onBeforeUnmount(() => {
  if (player.value) {
    const percentage = getPlaybackPercentage(player.value.currentTime, player.value.duration)
    setViewStorage('browsHistory', { ...videoExtend.value?.javVideoDTO, ratio: `${percentage}%` })
  }
})
onUnmounted(() => {
  if (player.value) {
    player.value.pause() // 停止播放
    player.value.destroy() // 销毁播放器实例
    player.value = null
    console.log('Player destroyed on exit.')
  }
})

function initPlayer(newUrl) {
  console.log('xxxx', newUrl)
  if (player.value)
    return
  const url = getNewVideo(newUrl)
  player.value = new Player({
    id: 'vs',
    autoplay: true,
    volume: 0.6,
    url,
    height: 210,
    width: window.innerWidth
  })
  player.value.on('pause', () => {
    console.warn('>>>>pause')
  })
  player.value.on('play', () => {
    console.log('>>>>play')
  })
  player.value.on('seeking', () => {
    console.log('>>>>seeking')
  })
  player.value.on('seeked', () => {
    console.log('>>>>seeked')
  })
  player.value.on('error', (error) => {
    console.log('player:::error', error)
  })
}
function playNext(newUrl: string) {
  const url = getNewVideo(newUrl)
  console.log(url)
  player.value?.playNext({
    autoplay: true,
    url: url.toString()
  })
}
async function init() {
  if (videoExtend.value && videoExtend.value.javVideoDTO) {
    const javVideoDTO = videoExtend.value.javVideoDTO
    if (javVideoDTO.videoUrl) {
      const newUrl = javVideoDTO.videoUrl
      if (player.value) {
        playNext(newUrl)
      } else {
        initPlayer(newUrl)
      }
    }
  }
}
</script>

<template>
  <div overflow-x-hidden>
    <span class="absolute left-0 top-0 z-12 p-2" @click="onClickLeft">
      <img :src="icon_back" class="w-4" />
    </span>
    <section id="wrapper" class="h-210px w-full">
      <div id="vs" />
    </section>

    <div class="px-15px pt-12px">
      <p ct-E8E8E8 text="15px">{{ videoExtend?.javVideoDTO?.videoName }}</p>

      <div class="">
        <div flex items-center>
          <span ct-e8e8e8>影片信息</span>
        </div>

        <div id="video-info">
          <a flex ct-e8e8e8 class="text-12px">
            <span>播放:&nbsp;</span>
            <span>{{ videoExtend?.javVideoDTO?.playCountTotal }}w</span>
          </a>

          <a mt-6px flex ct-e8e8e8 class="text-12px">
            <span>番号:&nbsp;</span>
            <span>{{ videoExtend?.javVideoDTO?.videoNo }}</span>
          </a>

          <a mt-6px flex ct-e8e8e8 class="text-12px">
            <span>国籍:&nbsp;</span>
            <span ct-FD5B03>{{ nation?.title }}</span>
          </a>

          <a mt-6px flex ct-e8e8e8 class="text-12px">
            <span>更新日期:&nbsp;</span>
            <span>{{ videoExtend?.javVideoDTO?.initUpdatedAt }}</span>
          </a>
        </div>

        <div id="keys-chip-box" mt-20px flex flex-wrap items-center>
          <a
            v-for="(item, index) in categoryTagNameList"
            :key="index"

             mb-12px mr-12px h-22px w-auto flex items-center justify-center whitespace-nowrap rd-3px px-12px text-12px bg-373c59 ct-e8e8e8
          >
            {{ item }}
          </a>
        </div>
        <CommonCellView21 pb-5 title="相关影片" :list="guessList">
          <template #="{ item }">
            <HorizontalCard1 :item="item" />
          </template>
        </CommonCellView21>
      </div>
    </div>
  </div>
</template>

<style scoped>
:deep(.xgplayer .btn-text span){
  min-width: 30px;
}
:deep(.xgplayer .xgplayer-progress){
  max-width: 136px;
}
</style>
